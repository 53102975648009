import * as React from "react"
import {  graphql } from "gatsby"
//import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = ({ data, location }) => {
 const contactTitle = data.site.siteMetadata?.title || `Contact me`

    return (
     <Layout location={location} title={contactTitle}>
      <Seo title="Contact page" />
      <h1>Contact me</h1>
      <p>clintdatagroove@gmail.com</p>
     </Layout>
    );

};

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
